import { useContext } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import i18nContext from 'components/i18n-context';
import ComplexTranslate from 'components/ComplexTranslate';
import Input from 'uikit/Input/Input';
import Button from 'uikit/Button/Button';
import { deleteNonLatin, emailRegExp, replaceSpaces } from 'services/utils';

const BusinessRegistration = ({ registrationStore }) => {
  const i18n = useContext(i18nContext);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      companyName: registrationStore.businessFields.companyName,
      email: registrationStore.generalFields.email
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('register.error.emptyCompany'))
        .max(250, i18n.getMessage('error.Size')),
      email: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('register.error.emptyEmail'))
        .max(50, i18n.getMessage('error.emailSize'))
        .trim()
        .matches(emailRegExp, { message: i18n.getMessage('register.error.invalidEmail') })
        .email(i18n.getMessage('register.error.invalidEmail'))
    }),
    onSubmit: (values) => {
      registrationStore.setCompanyName(values.companyName.trim());
      registrationStore.setEmail(replaceSpaces(values.email));
      registrationStore.registerBusinessAccount();
    }
  });

  const { values, errors, handleSubmit, handleChange, validateField, submitCount } = form;

  const serverFieldErrors = registrationStore?.error?.fields || {};

  return (
    <>
      <form action='#' id='send' onSubmit={handleSubmit} className='form'>
        <Input
          type={'email_phone'}
          label={i18n.getMessage('register.label.company')}
          placeholder={i18n.getMessage('register.company.placeholder')}
          name={'companyName'}
          value={deleteNonLatin(values.companyName)}
          onChange={handleChange}
          onBlur={() => validateField('companyName')}
          initialStatus={submitCount}
          autoFocus={true}
          error={
            errors?.companyName ||
            (serverFieldErrors?.companyName && i18n.getMessage(`error.${serverFieldErrors.companyName}`))
          }
        />
        <Input
          type={'email_phone'}
          label={i18n.getMessage('register.label.email')}
          placeholder={i18n.getMessage('register.email.placeholder')}
          name={'email'}
          value={replaceSpaces(values.email)}
          onChange={handleChange}
          onBlur={() => validateField('email')}
          initialStatus={submitCount}
          error={errors?.email || (serverFieldErrors?.email && i18n.getMessage(`error.${serverFieldErrors.email}`))}
        />
        <Button type={'primary'} roleType={'submit'} size={'large'} fullWidth={true} onClick={() => {}}>
          {i18n.getMessage('login.link.create')}
        </Button>
      </form>
      <div className='register-createAccount-wrapper'>
        <ComplexTranslate
          text={'register.text.creatingAccount'}
          interpolation={{
            user: (
              <a
                href={process.env.REACT_APP_LINK_TERMS_AND_CONDITIONS}
                target='_blank'
                className='link-normal'
                rel='noreferrer'
              >
                {i18n.getMessage('register.text.userAgreement')}
              </a>
            ),
            privacy: (
              <a
                href={process.env.REACT_APP_LINK_PRIVACY_POLICY}
                target='_blank'
                className='link-normal'
                rel='noreferrer'
              >
                {i18n.getMessage('register.text.userPrivacy')}
              </a>
            )
          }}
        />
      </div>
    </>
  );
};

BusinessRegistration.propTypes = {
  registrationStore: MobXPropTypes.observableObject,
  error: PropTypes.any
};

export default inject('registrationStore')(observer(BusinessRegistration));
