import { ReactTagManager } from 'react-gtm-ts';
import { getCookie, setCookie } from 'services/authUtils';

export const initializeAnalytics = () => {
  const gtmCode = process.env.REACT_APP_GTAG_ID;

  if (!gtmCode) return;

  ReactTagManager.init({
    code: gtmCode,
    debug: false, // Enable debug mode if needed
    performance: false // Optional: load GTM after user interaction
  });
};

export const pushDataLayerEvent = (eventName, data) => {
  ReactTagManager.action({
    event: eventName,
    ...data
  });
};

export const pushDataLayerEventOnce = (eventName, data) => {
  if (!data || !data?.customer_id || !eventName || hasEventAlreadyBeenSent(eventName, data.customer_id)) return;

  pushDataLayerEvent(eventName, data);
  markEventAsSent(eventName, data.customer_id);
};

// Function to check if the event has been sent using cookies
const hasEventAlreadyBeenSent = (eventName, customerId) => !!getCookie(`event_${eventName}_${customerId}`);

// Function to mark the event as sent by setting a cookie
const markEventAsSent = (eventName, customerId) => {
  // Set the cookie with a 1-year expiration
  setCookie(`event_${eventName}_${customerId}`, 'true', { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
};
