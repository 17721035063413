import { strToFloat, trimTextContent } from 'services/utils';

export default ({ providerTableData, data }) => ({
  paymentMethod: data.paymentMethod || null,
  senderWallet: (data.wallet && data.wallet?.wallet_number?.trim()) || '',
  currency: (data.wallet && data.wallet?.currency?.trim()) || '',
  amount: strToFloat(providerTableData?.amount?.toString() || ''),
  recipient: {
    walletAddress: providerTableData.recipient_wallet,
    network: (data.wallet && data.wallet?.network?.trim()) || ''
  },
  comment: trimTextContent(providerTableData?.comment) || null,
  documents: data.uploadedFiles ? data.uploadedFiles : null
});
