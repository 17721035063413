import { useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Manual from './Manual';
import {
  CUSTOMER_TYPE,
  DATA_LAYER_EVENTS,
  KYC_PROVIDERS,
  USER_CUSTOMER_STATUSES,
  VERIFICATION_TYPES
} from 'components/common/constants';
import NoOutlet from 'components/common/NoOutlet/NoOutlet';
import Shufti from 'components/Verification/Shufti';
import Sumsub from 'components/Verification/Sumsub';
import { pushDataLayerEventOnce } from 'services/analytics';
import { getCustomerId, getCustomerType, getInternalUserId } from 'services/authUtils';
import './Verification.scss';

const Verification = ({ userStore }) => {
  const customerNumber = userStore?.userData.account?.account_number;
  const applicantLevel = userStore?.userData?.account?.kyc_level_name
    ? userStore?.userData?.account?.kyc_level_name
    : userStore?.userData.account?.type === CUSTOMER_TYPE.INDIVIDUAL
      ? VERIFICATION_TYPES.INDIVIDUAL
      : VERIFICATION_TYPES.COMPANY;

  const kycProvider = userStore.getKycProvider();
  const kybProvider = userStore.getKybProvider();

  useEffect(() => {
    pushDataLayerEventOnce(DATA_LAYER_EVENTS.VERIFICATION_START, {
      user_id_internal: getInternalUserId(),
      customer_id: getCustomerId(),
      customer_type: getCustomerType()
    });
  }, []);

  const beginVerification = async (customerNumber, data) => await userStore?.beginVerification(customerNumber, data);

  if (!userStore.userData?.account) {
    return <NoOutlet error={userStore.error} />;
  }
  if (userStore?.userData.account?.status === USER_CUSTOMER_STATUSES.DECLINED) {
    return <NoOutlet error={{ code: USER_CUSTOMER_STATUSES.DECLINED }} verificationType={'TEST'} />;
  }
  if (userStore?.userData.account?.status === USER_CUSTOMER_STATUSES.CLOSED) {
    return <NoOutlet error={{ code: USER_CUSTOMER_STATUSES.CLOSED }} verificationType={'TEST'} />;
  }

  const provider = userStore?.userData?.account?.type === CUSTOMER_TYPE.INDIVIDUAL ? kycProvider : kybProvider;

  return (
    <>
      {provider === KYC_PROVIDERS.SHUFTI && <Shufti customerNumber={customerNumber} />}
      {provider === KYC_PROVIDERS.SUMSUB && (
        <Sumsub
          email={userStore?.userData.account?.email}
          phone={userStore?.userData.phone}
          customerNumber={customerNumber}
          levelName={applicantLevel}
          accessToken={userStore?.verificationAccessToken}
          beginVerification={beginVerification}
        />
      )}
      {provider === KYC_PROVIDERS.MANUAL && <Manual />}
    </>
  );
};

Verification.propTypes = {
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore
}))(observer(Verification));
