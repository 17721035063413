import { useRef } from 'react';
import PropTypes from 'prop-types';
import Input from 'uikit/Input/Input';

const AmountInput = ({
  name,
  error,
  value,
  onChange,
  onBlur = () => {},
  label,
  placeholder,
  subText,
  className,
  isDisabled,
  isRequired,
  precision = 2
}) => {
  const inputRef = useRef(null);

  // Function to add commas as thousand separators
  const addCommas = (value) => {
    if (!value) return '';

    let [integerPart, decimalPart] = value.replace(/,/g, '').split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
  };

  // Function to filter user input
  const filterInputtedValue = (value) => {
    const [integerPart, ...fractionParts] = value.split('.');

    let filteredValue = integerPart;
    if (!integerPart) return '';

    if (fractionParts.length > 0) {
      filteredValue += '.' + fractionParts.join('').substring(0, precision);
    }

    return filteredValue.replace(/[^0-9.]/g, '');
  };

  // Handle user input
  const handleChange = (e) => {
    const rawValue = e.target.value;
    const cursorPosition = e.target.selectionStart;

    // Remove commas and filter invalid characters
    let cleanValue = rawValue.replace(/,/g, '');
    cleanValue = filterInputtedValue(cleanValue);

    onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: cleanValue
      }
    });

    // Restore cursor position
    setTimeout(() => {
      if (inputRef.current) {
        let newCursorPosition = cursorPosition + (addCommas(cleanValue).length - rawValue.length);
        inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    }, 0);
  };

  return (
    <Input
      inputRef={inputRef}
      name={name}
      error={error}
      value={addCommas(value)}
      onChange={handleChange}
      onBlur={onBlur}
      label={label}
      placeholder={placeholder}
      subText={subText}
      className={className}
      isDisabled={isDisabled}
      isRequired={isRequired}
    />
  );
};

AmountInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  subText: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  precision: PropTypes.number
};

export default AmountInput;
