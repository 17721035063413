import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as WarningIcon } from 'assets/iconButtons/warning.svg';
import i18nContext from 'components/i18n-context';

const PopUpCryptoTermsScheme = ({ onAccept, className }) => {
  const i18n = useContext(i18nContext);

  return (
    <div className={`scheme-wrapper pop-up-crypto-terms ${className}`}>
      <WarningIcon height={100} width={100} />
      <h2 className='scheme-success-header'>{i18n.getMessage('popUp.cryptoTerms.caption')}</h2>
      <p className='scheme-success-message'>
        <span dangerouslySetInnerHTML={{ __html: i18n.getMessage('popUp.cryptoTerms.content') }}></span>
        <br />
        <a href={'https://grapherex.com/terms/'}>{i18n.getMessage('popUp.cryptoTerms.termsAndConditions')}</a>
        <br />
        <a href={'https://grapherex.com/privacy-policy/'}>{i18n.getMessage('popUp.cryptoTerms.privacyPolicy')}</a>
      </p>
      <button className='scheme-success-button' onClick={() => onAccept()}>
        {i18n.getMessage('popUp.cryptoTerms.buttonText')}
      </button>
    </div>
  );
};

PopUpCryptoTermsScheme.propTypes = {
  onAccept: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string
};

export default PopUpCryptoTermsScheme;
