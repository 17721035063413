import React, { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import { amountFormattedValue } from 'services/utils';
import './FeesTable.scss';

export const FeesTable = ({
  lastUpdate,
  paymentMethod,
  transactionType,
  tariffName,
  transactional,
  mobileText,
  feeType,
  tariffData
}) => {
  const i18n = useContext(i18nContext);
  const tariff = tariffData[0];

  const MultipleFields = ({ tariffData }) => {
    const data = tariffData[0];
    return (
      <div className='multiple-tariff-container'>
        <div>
          {data.min ? (
            <div className='value tariff'>
              <div className='multiple-tariff-line'>
                <div className='tariff'>{i18n.getMessage('fees.tariffValue.min')}</div>
                <div className={'multiple-tariff-value'}>{amountFormattedValue(data.min, 2) + ' ' + data.currency}</div>
              </div>
            </div>
          ) : null}
        </div>
        <div>
          {data.max ? (
            <div className='value tariff'>
              <div className='multiple-tariff-line'>
                <div className='tariff'>{i18n.getMessage('fees.tariffValue.max')}</div>
                <div className={'multiple-tariff-value'}>{amountFormattedValue(data.max, 2) + ' ' + data.currency}</div>
              </div>
            </div>
          ) : null}
        </div>
        <div className='value tariff'>
          <div className='multiple-tariff-line'>
            <div className='tariff'>{i18n.getMessage('fees.tariffValue.percent')} </div>
            <div className={'multiple-tariff-value'}>{amountFormattedValue(data.max, 2) + ' ' + '%'}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='row list'>
      {transactional ? (
        <>
          <div className='row-item'>
            <p className='row-mobile-text'>{mobileText?.paymentMethod}</p>
            <p className='value method'>{paymentMethod}</p>
          </div>
          <div className='row-item'>
            <p className='row-mobile-text'>{mobileText?.type}</p>
            <p className='value type'>{transactionType}</p>
          </div>
        </>
      ) : (
        <div className='row-item'>
          <p className='row-mobile-text'>{mobileText?.tariffName}</p>
          <p className='value name'>{tariffName}</p>
        </div>
      )}
      <div className='row-item'>
        <div className='row-mobile-text'>{mobileText?.tariffValue}</div>
        {feeType !== 'PERCENT' ? (
          <p className='value tariff'>{amountFormattedValue(tariff.fixed_value, 2) + ' ' + tariff.currency}</p>
        ) : (
          <MultipleFields tariffData={tariffData} />
        )}
      </div>
      <div className='row-item'>
        <p className='row-mobile-text'>{mobileText?.dateLastUpdates}</p>
        <p className='value date'>{lastUpdate?.date}</p>
        <p className='value time'>{lastUpdate?.time}</p>
      </div>
    </div>
  );
};
