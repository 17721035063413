import { ReactComponent as AED } from 'assets/currencies/AED.svg';
import { ReactComponent as ARS } from 'assets/currencies/ARS.svg';
import { ReactComponent as AUD } from 'assets/currencies/AUD.svg';
import { ReactComponent as BBD } from 'assets/currencies/BBD.svg';
import { ReactComponent as BDT } from 'assets/currencies/BDT.svg';
import { ReactComponent as BGN } from 'assets/currencies/BGN.svg';
import { ReactComponent as BHD } from 'assets/currencies/BHD.svg';
import { ReactComponent as BRL } from 'assets/currencies/BRL.svg';
import { ReactComponent as BWP } from 'assets/currencies/BWP.svg';
import { ReactComponent as CAD } from 'assets/currencies/CAD.svg';
import { ReactComponent as CHF } from 'assets/currencies/CHF.svg';
import { ReactComponent as CLP } from 'assets/currencies/CLP.svg';
import { ReactComponent as CNY } from 'assets/currencies/CNY.svg';
import { ReactComponent as COP } from 'assets/currencies/COP.svg';
import { ReactComponent as CZK } from 'assets/currencies/CZK.svg';
import { ReactComponent as DKK } from 'assets/currencies/DKK.svg';
import { ReactComponent as EUR } from 'assets/currencies/EUR.svg';
import { ReactComponent as FJD } from 'assets/currencies/FJD.svg';
import { ReactComponent as GBP } from 'assets/currencies/GBP.svg';
import { ReactComponent as GHS } from 'assets/currencies/GHS.svg';
import { ReactComponent as HKD } from 'assets/currencies/HKD.svg';
import { ReactComponent as HRK } from 'assets/currencies/HRK.svg';
import { ReactComponent as HUF } from 'assets/currencies/HUF.svg';
import { ReactComponent as IDR } from 'assets/currencies/IDR.svg';
import { ReactComponent as INR } from 'assets/currencies/INR.svg';
import { ReactComponent as ILS } from 'assets/currencies/ILS.svg';
import { ReactComponent as JMD } from 'assets/currencies/JMD.svg';
import { ReactComponent as JOD } from 'assets/currencies/JOD.svg';
import { ReactComponent as JPY } from 'assets/currencies/JPY.svg';
import { ReactComponent as KES } from 'assets/currencies/KES.svg';
import { ReactComponent as KRW } from 'assets/currencies/KRW.svg';
import { ReactComponent as KWD } from 'assets/currencies/KWD.svg';
import { ReactComponent as LKR } from 'assets/currencies/LKR.svg';
import { ReactComponent as LSL } from 'assets/currencies/LSL.svg';
import { ReactComponent as MAD } from 'assets/currencies/MAD.svg';
import { ReactComponent as MUR } from 'assets/currencies/MUR.svg';
import { ReactComponent as MWK } from 'assets/currencies/MWK.svg';
import { ReactComponent as MXN } from 'assets/currencies/MXN.svg';
import { ReactComponent as MYR } from 'assets/currencies/MYR.svg';
import { ReactComponent as MZN } from 'assets/currencies/MZN.svg';
import { ReactComponent as NAD } from 'assets/currencies/NAD.svg';
import { ReactComponent as NOK } from 'assets/currencies/NOK.svg';
import { ReactComponent as NPR } from 'assets/currencies/NPR.svg';
import { ReactComponent as NZD } from 'assets/currencies/NZD.svg';
import { ReactComponent as OMR } from 'assets/currencies/OMR.svg';
import { ReactComponent as PEN } from 'assets/currencies/PEN.svg';
import { ReactComponent as PGK } from 'assets/currencies/PGK.svg';
import { ReactComponent as PHP } from 'assets/currencies/PHP.svg';
import { ReactComponent as PKR } from 'assets/currencies/PKR.svg';
import { ReactComponent as PLN } from 'assets/currencies/PLN.svg';
import { ReactComponent as QAR } from 'assets/currencies/QAR.svg';
import { ReactComponent as RON } from 'assets/currencies/RON.svg';
import { ReactComponent as RUB } from 'assets/currencies/RUB.svg';
import { ReactComponent as SAR } from 'assets/currencies/SAR.svg';
import { ReactComponent as SEK } from 'assets/currencies/SEK.svg';
import { ReactComponent as SGD } from 'assets/currencies/SGD.svg';
import { ReactComponent as THB } from 'assets/currencies/THB.svg';
import { ReactComponent as TND } from 'assets/currencies/TND.svg';
import { ReactComponent as TRY } from 'assets/currencies/TRY.svg';
import { ReactComponent as TTD } from 'assets/currencies/TTD.svg';
import { ReactComponent as TWD } from 'assets/currencies/TWD.svg';
import { ReactComponent as TZS } from 'assets/currencies/TZS.svg';
import { ReactComponent as UGX } from 'assets/currencies/UGX.svg';
import { ReactComponent as USD } from 'assets/currencies/USD.svg';
import { ReactComponent as UYU } from 'assets/currencies/UYU.svg';
import { ReactComponent as VND } from 'assets/currencies/VND.svg';
import { ReactComponent as ZAR } from 'assets/currencies/ZAR.svg';
import { ReactComponent as ZMW } from 'assets/currencies/ZMW.svg';
import { ReactComponent as USDC } from 'assets/currencies/USDC.svg';
import { ReactComponent as USDT } from 'assets/currencies/USDT.svg';
import { ReactComponent as OTHERS_CURRENCIES } from 'assets/currencies/OTHERS.svg';
// Crypto currencies
import { ReactComponent as ARB } from 'assets/currencies/ARB.svg';
import { ReactComponent as USDC_ARB } from 'assets/currencies/USDC_ARB.svg';
import { ReactComponent as BTC } from 'assets/currencies/BTC.svg';
import { ReactComponent as ETH } from 'assets/currencies/ETH.svg';
import { ReactComponent as EURC_ETH } from 'assets/currencies/EURC_ETH.svg';
import { ReactComponent as USDC_ETH } from 'assets/currencies/USDC_ETH.svg';
import { ReactComponent as USDT_ETH } from 'assets/currencies/USDT_ETH.svg';
import { ReactComponent as SOL } from 'assets/currencies/SOL.svg';
import { ReactComponent as USDC_SOL } from 'assets/currencies/USDC_SOL.svg';
import { ReactComponent as USDT_SOL } from 'assets/currencies/USDT_SOL.svg';
import { ReactComponent as TRX } from 'assets/currencies/TRX.svg';
import { ReactComponent as USDC_TRX } from 'assets/currencies/USDC_TRX.svg';
import { ReactComponent as USDT_TRX } from 'assets/currencies/USDT_TRX.svg';
import { ReactComponent as XLM } from 'assets/currencies/XLM.svg';
import { ReactComponent as USDC_XLM } from 'assets/currencies/USDC_XLM.svg';

export const CRYPTO_CURRENCY_PRECISION = 8;

export const STABLE_COINS = ['USDT', 'USDC', 'EURC'];

export const isStableCoin = (currencyCode) => STABLE_COINS.includes(currencyCode);

export const CURRENCIES = {
  OTHERS: { icon: OTHERS_CURRENCIES },
  AED: { code: 'AED', symbol: 'د.إ', icon: AED },
  ARS: { code: 'ARS', symbol: '$', icon: ARS },
  AUD: { code: 'AUD', symbol: '$', icon: AUD },
  BBD: { code: 'BBD', symbol: '$', icon: BBD },
  BDT: { code: 'BDT', symbol: '৳', icon: BDT },
  BGN: { code: 'BGN', symbol: 'лв', icon: BGN },
  BHD: { code: 'BHD', symbol: '.د.ب', icon: BHD },
  BRL: { code: 'BRL', symbol: 'R$', icon: BRL },
  BWP: { code: 'BWP', symbol: 'P', icon: BWP },
  CAD: { code: 'CAD', symbol: 'C$', icon: CAD },
  CHF: { code: 'CHF', symbol: 'Fr', icon: CHF },
  CLP: { code: 'CLP', symbol: '$', icon: CLP },
  CNY: { code: 'CNY', symbol: '¥', icon: CNY },
  COP: { code: 'COP', symbol: '$', icon: COP },
  CZK: { code: 'CZK', symbol: 'Kč', icon: CZK },
  DKK: { code: 'DKK', symbol: 'kr', icon: DKK },
  EUR: { code: 'EUR', symbol: '€', icon: EUR },
  FJD: { code: 'FJD', symbol: 'FJ$', icon: FJD },
  GBP: { code: 'GBP', symbol: '£', icon: GBP },
  GHS: { code: 'GHS', symbol: '₵', icon: GHS },
  HKD: { code: 'HKD', symbol: 'HK$', icon: HKD },
  HRK: { code: 'HRK', symbol: 'kn', icon: HRK },
  HUF: { code: 'HUF', symbol: 'Ft', icon: HUF },
  IDR: { code: 'IDR', symbol: 'Rp', icon: IDR },
  ILS: { code: 'ILS', symbol: '₪', icon: ILS },
  INR: { code: 'INR', symbol: '₹', icon: INR },
  JMD: { code: 'JMD', symbol: 'J$', icon: JMD },
  JOD: { code: 'JOD', symbol: 'د.ا', icon: JOD },
  JPY: { code: 'JPY', symbol: '¥', icon: JPY },
  KES: { code: 'KES', symbol: 'KSh', icon: KES },
  KRW: { code: 'KRW', symbol: '₩', icon: KRW },
  KWD: { code: 'KWD', symbol: 'د.ك', icon: KWD },
  LKR: { code: 'LKR', symbol: 'Rs', icon: LKR },
  LSL: { code: 'LSL', symbol: 'L', icon: LSL },
  MAD: { code: 'MAD', symbol: 'د.م.', icon: MAD },
  MUR: { code: 'MUR', symbol: '₨', icon: MUR },
  MWK: { code: 'MWK', symbol: 'MK', icon: MWK },
  MXN: { code: 'MXN', symbol: '$', icon: MXN },
  MYR: { code: 'MYR', symbol: 'RM', icon: MYR },
  MZN: { code: 'MZN', symbol: 'MT', icon: MZN },
  NAD: { code: 'NAD', symbol: '$', icon: NAD },
  NOK: { code: 'NOK', symbol: 'kr', icon: NOK },
  NPR: { code: 'NPR', symbol: 'Rs', icon: NPR },
  NZD: { code: 'NZD', symbol: 'NZ$', icon: NZD },
  OMR: { code: 'OMR', symbol: 'ر.ع.', icon: OMR },
  PEN: { code: 'PEN', symbol: 'S/', icon: PEN },
  PGK: { code: 'PGK', symbol: 'K', icon: PGK },
  PHP: { code: 'PHP', symbol: '₱', icon: PHP },
  PKR: { code: 'PKR', symbol: 'Rs', icon: PKR },
  PLN: { code: 'PLN', symbol: 'zł', icon: PLN },
  QAR: { code: 'QAR', symbol: 'ر.ق', icon: QAR },
  RON: { code: 'RON', symbol: 'lei', icon: RON },
  RUB: { code: 'RUB', symbol: '₽', icon: RUB },
  SAR: { code: 'SAR', symbol: '﷼', icon: SAR },
  SEK: { code: 'SEK', symbol: 'kr', icon: SEK },
  SGD: { code: 'SGD', symbol: 'S$', icon: SGD },
  THB: { code: 'THB', symbol: '฿', icon: THB },
  TND: { code: 'TND', symbol: 'د.ت', icon: TND },
  TRY: { code: 'TRY', symbol: '₺', icon: TRY },
  TTD: { code: 'TTD', symbol: 'TT$', icon: TTD },
  TWD: { code: 'TWD', symbol: 'NT$', icon: TWD },
  TZS: { code: 'TZS', symbol: 'TSh', icon: TZS },
  UGX: { code: 'UGX', symbol: 'USh', icon: UGX },
  USD: { code: 'USD', symbol: '$', icon: USD },
  UYU: { code: 'UYU', symbol: '$U', icon: UYU },
  VND: { code: 'VND', symbol: '₫', icon: VND },
  XAF: { code: 'XAF', symbol: 'FCFA' },
  XCD: { code: 'XCD', symbol: '$' },
  XOF: { code: 'XOF', symbol: 'CFA' },
  ZAR: { code: 'ZAR', symbol: 'R', icon: ZAR },
  ZMW: { code: 'ZMW', symbol: 'K', icon: ZMW },
  ETH_USDT: { code: 'ETH_USDT', symbol: 'USDT', icon: USDT },
  TRX_USDT: { code: 'TRX_USDT', symbol: 'USDT', icon: USDT },
  TRX_TRX: { code: 'TRX_TRX', symbol: 'TRX', icon: TRX },
  SOL_USDT: { code: 'SOL_USDT', symbol: 'USDT', icon: USDT },
  XLM_USDT: { code: 'XLM_USDT', symbol: 'USDT', icon: USDT },
  TRX_TEST: { code: 'TRX_TEST', symbol: 'TRX', icon: TRX },
  USDT_ERC20: { code: 'USDT_ERC20', symbol: 'USDT', icon: USDT },
  TRX_USDT_S2UZ: { code: 'TRX_USDT_S2UZ', symbol: 'USDT', icon: USDT },
  TRX_USDC_6NU3: { code: 'TRX_USDC_6NU3', symbol: 'USDC', icon: USDC },
  SOL_USDT_EWAY: { code: 'SOL_USDT_EWAY', symbol: 'USDT', icon: USDT },
  ARB: { code: 'ARB', symbol: 'ARB', icon: ARB },
  BTC: { code: 'BTC', symbol: 'BTC', icon: BTC },
  ETH: { code: 'ETH', symbol: 'ETH', icon: ETH },
  SOL: { code: 'SOL', symbol: 'SOL', icon: SOL },
  TRX: { code: 'TRX', symbol: 'TRX', icon: TRX },
  XLM: { code: 'SOL', symbol: 'SOL', icon: SOL },
  USDT: {
    code: 'USDT',
    symbol: 'USDT',
    networks: {
      TRX: {
        code: 'TRX',
        icon: USDT_TRX
      },
      ETH: {
        code: 'ETH',
        icon: USDT_ETH
      },
      SOL: {
        code: 'SOL',
        icon: USDT_SOL
      }
    }
  },
  USDC: {
    code: 'USDC',
    symbol: 'USDC',
    networks: {
      TRX: {
        code: 'TRX',
        icon: USDC_TRX
      },
      ETH: {
        code: 'ETH',
        icon: USDC_ETH
      },
      SOL: {
        code: 'SOL',
        icon: USDC_SOL
      },
      XLM: {
        code: 'XLM',
        icon: USDC_XLM
      },
      ARB: {
        code: 'ARB',
        icon: USDC_ARB
      }
    }
  },
  EURC: {
    code: 'EURC',
    symbol: 'EURC',
    networks: {
      ETH: {
        code: 'ETH',
        icon: EURC_ETH
      }
    }
  }
};
