import i18n from 'i18n';

export const REGISTRATION_CUSTOMER_TYPE = {
  PERSONAL: 'PERSONAL',
  BUSINESS: 'BUSINESS'
};

export const CUSTOMER_TYPE = {
  INDIVIDUAL: 'individual',
  COMPANY: 'corporate'
};

export const REGISTRATION_STATUSES = {
  REGISTRATION_STATUS_EMAIL_CONFIRMATION: 'EMAIL_CONFIRMATION',
  REGISTRATION_STATUS_SECOND_FACTOR_CONFIRMATION_PHONE: 'PHONE_SETUP',
  REGISTRATION_STATUS_PHONE_CONFIRMATION: 'PHONE_CONFIRMATION',
  REGISTRATION_STATUS_CONFIRMED: 'CONFIRMED'
};

export const REGISTRATION_STEPS = {
  USER_REGISTRATION: 0,
  USER_REGISTRATION_CONFIRMATION: 1,
  CUSTOMER_CREATION: 2,
  CUSTOMER_CREATION_CONFIRMATION: 3,
  REGISTRATION_COMPLETED: 4
};

export const DATA_LAYER_EVENTS = {
  REGISTRATION_SUCCESS: 'registration_success',
  REGISTRATION_SUCCESS_ADDITIONAL: 'registration_success_additional',
  LOGIN_SUCCESS: 'login_success',
  VERIFICATION_START: 'verification_start'
};

export const PASSWORD_RECOVERY_STATUSES = {
  PHONE_CONFIRM: 'PHONE_CONFIRM',
  EMAIL_CHECK: 'EMAIL_CHECK',
  EMAIL_CONFIRM: 'EMAIL_CONFIRM',
  PASSWORD_SETUP: 'PASSWORD_SETUP'
};

export const TWO_FACTOR_AUTH_TYPES = {
  SMS: 'SMS',
  GOOGLE_AUTH: 'GOOGLE_AUTH'
};

export const BACKEND_MODULE_TYPES = {
  API: 'API',
  OAUTH: 'OAUTH',
  PAYMENT: 'PAYMENT',
  MESSENGER: 'MESSENGER'
};

export const CONFIRMATION_HANDLE_TYPES = {
  ON_CHANGE: 'ON_CHANGE',
  ON_COMPLETE: 'ON_COMPLETE'
};

export const ICON_BUTTONS_TYPE = {
  NAVIGATION: 'NAVIGATION',
  MENU: 'MENU'
};

export const USER_CUSTOMER_STATUSES = {
  NEW: 'NEW',
  CREATED: 'CREATED',
  VERIFICATION: 'VERIFICATION',
  VERIFIED: 'VERIFIED',
  DECLINED: 'DECLINED',
  VIRTUAL: 'VIRTUAL',
  CLOSED: 'CLOSED'
};

export const KYC_STATUSES = {
  NEW: 'NEW',
  UNDER_REVIEW: 'UNDER_REVIEW',
  VERIFIED: 'VERIFIED',
  DECLINED: 'DECLINED'
};

export const TRANSACTIONS_TABLE_HEADER = ['date', 'status', 'fromTo', 'purpose', 'amountCommission', 'paymentDetails'];
export const TRANSACTIONS_TABLE_STATUS = {
  created: 'CREATED',
  onHold: 'ON_HOLD',
  pending: 'PENDING',
  processing: 'PROCESSING',
  completed: 'PAID',
  rejected: 'REJECTED',
  failed: 'FAILED',
  acceptRequest: 'ACCEPT_REQUESTED',
  rejectRequest: 'REJECT_REQUESTED',
  monitoring: 'MONITORING'
};

export const PAYMENT_OBLIGATIONS_TABLE_HEADER = ['date', 'purpose', 'amount', 'paymentDetails'];

export const PAYMENT_OBLIGATION_STATUSES = {
  PAID: 'PAID',
  OPEN: 'OPEN',
  RELEASED: 'RELEASED'
};

export const DOCUMENT_STATUS = {
  OK: 'OK',
  REQUESTED: 'REQUESTED',
  PROVIDED: 'PROVIDED'
};

export const ACCOUNT_PROPERTIES = {
  IBAN: 'iban',
  MULTI_IBAN: 'multi_iban',
  ACCOUNT_NUMBER: 'wallet_number',
  MULTI_ACCOUNT_NUMBER: 'multi_wallet_number',
  AVAILABLE: 'available',
  CURRENCY: 'currency',
  FROZEN: 'frozen',
  MINIMAL: 'minimal',
  NETWORK: 'network',
  ON_HOLD: 'on_hold',
  STATUS: 'status',
  TOTAL: 'total',
  TRANSFER_PROVIDER: 'transfer_provider',
  TYPE: 'type'
};

export const TRANSACTION_TYPES = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING'
};

export const TYPES_OF_TRANSACTION = {
  EXCHANGE: 'EXCHANGE',
  COMMISSION: 'COMMISSION',
  NORMAL: 'NORMAL'
};

export const RECIPIENT_TYPES = {
  INDIVIDUAL: 'Individual',
  COMPANY: 'Company'
};

export const ACCOUNT_TOOLTIP_TEXT = [
  i18n.getMessage('tooltip.Transactions'),
  i18n.getMessage('tooltip.newPayment'),
  i18n.getMessage('tooltip.accountDetails')
];

export const WALLESTER_ACCOUNT_TOOLTIP_TEXT = [
  i18n.getMessage('tooltip.Transactions'),
  i18n.getMessage('tooltip.cards'),
  i18n.getMessage('tooltip.accountDetails')
];

export const TEMPLATES_TOOLTIP_TEXT = [
  i18n.getMessage('tooltip.newPayment'),
  i18n.getMessage('tooltip.editTemplate'),
  i18n.getMessage('tooltip.removeTemplate')
];

export const SESSION_STORAGE_CONSTANTS = {
  SESSION_ENDED: 'session ended'
};

export const SESSION_STORAGE_OPTIONS = {
  PASSWORD_RECOVERY: 'passwordRecovery',
  SESSION_ENDED: 'sessionEnded'
};

export const SMS_ERROR_CODE_DISABLE = [
  'CONFIRMATION_CODE_TRIES_EXCEED',
  'CONFIRMATION_CODE_IS_OUT_OF_DATE',
  'CODE_SEND_TIMEOUT'
];

export const CURRENCY_SYMBOLS = {
  EUR: '€',
  GBP: '£',
  USD: '$'
};

export const EXCHANGE_AMOUNT_DIRECTION = {
  AMOUNT_FROM: 'amountFrom',
  AMOUNT_TO: 'amountTo'
};

export const CUSTOMER_PERMISSIONS = {
  READ_ACCOUNT: 'READ_ACCOUNT',
  READ_PROFILE: 'READ_PROFILE',
  CREATE_TRANSACTION: 'CREATE_TRANSACTION',
  CREATE_INTERNAL_TRANSACTION: 'CREATE_INTERNAL_TRANSACTION',
  CREATE_CURRENCY_EXCHANGE: 'CREATE_CURRENCY_EXCHANGE',
  CREATE_REPORT: 'CREATE_REPORT',
  READ_FEES: 'READ_FEES',
  READ_CARDS: 'READ_CARDS',
  CREATE_CARDS: 'CREATE_CARDS',
  READ_MESSAGES: 'READ_MESSAGES',
  CREATE_MESSAGE: 'CREATE_MESSAGE'
};

export const PAYMENT_METHODS = [
  'SEPA',
  'SEPA_INSTANT',
  'TARGET2',
  'SWIFT',
  'FASTER_PAYMENTS',
  'INTERNAL',
  'INTERNAL_PROVIDER',
  'CHAPS',
  'BACS',
  'CRYPTO'
];

export const PAYMENT_METHOD = {
  SEPA: 'SEPA',
  SEPA_INSTANT: 'SEPA_INSTANT',
  TARGET2: 'TARGET2',
  SWIFT: 'SWIFT',
  FASTER_PAYMENTS: 'FASTER_PAYMENTS',
  INTERNAL: 'INTERNAL',
  INTERNAL_PROVIDER: 'INTERNAL_PROVIDER',
  CHAPS: 'CHAPS',
  BACS: 'BACS',
  CRYPTO: 'CRYPTO'
};

export const MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE = {
  EUR: [PAYMENT_METHOD.SEPA, PAYMENT_METHOD.SWIFT],
  GBP: [PAYMENT_METHOD.FASTER_PAYMENTS, PAYMENT_METHOD.SWIFT],
  OTHER: [PAYMENT_METHOD.SWIFT]
};

export const BEFORE_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE = {
  EUR: [
    PAYMENT_METHOD.SEPA,
    PAYMENT_METHOD.SEPA_INSTANT,
    PAYMENT_METHOD.TARGET2,
    PAYMENT_METHOD.SWIFT,
    PAYMENT_METHOD.INTERNAL,
    PAYMENT_METHOD.INTERNAL_PROVIDER
  ],
  GBP: [PAYMENT_METHOD.FASTER_PAYMENTS, PAYMENT_METHOD.INTERNAL, PAYMENT_METHOD.INTERNAL_PROVIDER],
  CRYPTO: [PAYMENT_METHOD.CRYPTO, PAYMENT_METHOD.INTERNAL],
  OTHER: [PAYMENT_METHOD.SWIFT, PAYMENT_METHOD.INTERNAL]
};

export const AFTER_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE = {
  EUR: [
    PAYMENT_METHOD.INTERNAL,
    PAYMENT_METHOD.INTERNAL_PROVIDER,
    PAYMENT_METHOD.SEPA_INSTANT,
    PAYMENT_METHOD.SEPA,
    PAYMENT_METHOD.TARGET2,
    PAYMENT_METHOD.SWIFT
  ],
  GBP: [
    PAYMENT_METHOD.INTERNAL,
    PAYMENT_METHOD.INTERNAL_PROVIDER,
    PAYMENT_METHOD.FASTER_PAYMENTS,
    PAYMENT_METHOD.BACS,
    PAYMENT_METHOD.CHAPS,
    PAYMENT_METHOD.SWIFT
  ],
  CRYPTO: [PAYMENT_METHOD.INTERNAL, PAYMENT_METHOD.CRYPTO],
  OTHER: [PAYMENT_METHOD.INTERNAL, PAYMENT_METHOD.SWIFT]
};

export const PAYMENT_PROVIDERS = {
  CLEARJUNCTION: 'CLEAR_JUNCTION',
  OPENPAYD: 'OPEN_PAYD',
  LPB: 'LPB',
  WALLESTER: 'WALLESTER',
  MANUAL: 'MANUAL',
  RYVYL: 'RYVYL',
  GRAPHEREX: 'GRAPHEREX'
};

export const CURRENCY_TYPES = {
  GBP: 'GBP',
  EUR: 'EUR',
  USD: 'USD'
};

export const FORM_TYPES = {
  SIMPLE: 'simple',
  ADVANCED: 'advanced'
};

export const KYC_PROVIDERS = {
  SUMSUB: 'SUMSUB',
  SHUFTI: 'SHUFTI',
  MANUAL: 'MANUAL'
};

export const VERIFICATION_TYPES = {
  COMPANY: process.env.REACT_APP_VERIFICATION_TYPE_COMPANY,
  INDIVIDUAL: process.env.REACT_APP_VERIFICATION_TYPE_INDIVIDUAL
};

export const MESSAGE_SENDER_TYPES = {
  USER: 'USER',
  OPERATOR: 'OPERATOR'
};

export const MESSAGE_TOPIC_PERMISSIONS = {
  ANNOUNCEMENT: 'READ',
  DEFAULT: 'READ_WRITE'
};

export const CARD_CREATION_FORM_TYPE = {
  VIRTUAL: 'virtual',
  PHYSICAL: 'physical',
  ONE_TIME_USAGE: 'one_time_usage'
};

export const CARD_TYPE = {
  VIRTUAL: 'VIRTUAL',
  PHYSICAL: 'PHYSICAL'
};

export const CARD_STATUS = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  SOFT_BLOCK: 'SOFT_BLOCK',
  HARD_BLOCK: 'HARD_BLOCK',
  CLOSED: 'CLOSED',
  REPLACE_REQUESTED: 'REPLACE_REQUESTED'
};

export const CARD_BLOCK_TYPE = {
  BLOCKED_BY_CARD_USER: 'BLOCKED_BY_CARD_USER',
  BLOCKED_BY_CARDHOLDER: 'BLOCKED_BY_CARDHOLDER',
  BLOCKED_BY_CARDHOLDER_VIA_PHONE: 'BLOCKED_BY_CARDHOLDER_VIA_PHONE',
  BLOCKED_BY_CLIENT: 'BLOCKED_BY_CLIENT', // blocked by finmatic
  BLOCKED_BY_ISSUER: 'BLOCKED_BY_ISSUER', // blocked by wallester
  COUNTERFEIT: 'COUNTERFEIT',
  FRAUDULENT: 'FRAUDULENT',
  LOST: 'LOST',
  STOLEN: 'STOLEN'
};

export const CARD_ACTIONS = {
  BLOCK: 'BLOCK',
  UNBLOCK: 'UNBLOCK',
  REPLACE: 'REPLACE',
  SUCCESS: 'SUCCESS'
};
export const SETTINGS_TABS = {
  PASSWORD_RECOVERY: 'passwordReset',
  MANAGE2FA: 'manage2fa',
  ACTIVITY_LOG: 'activityLog',
  USER_DETAILS: 'userDetails'
};

export const CARD_AUTHORIZATIONS_TABLE_HEADER = ['date', 'status', 'fromTo', 'amount', ''];
export const CARD_AUTHORIZATIONS_STATUS = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  COMPLETED: 'COMPLETED'
};

export const TOOLTIP_DIRECTION = {
  TOP: 'top',
  BOTTOM: 'bottom'
};

export const CARD_AUTHORIZATIONS_STATUSES = ['ACCEPTED', 'DECLINED', 'COMPLETED'];

export const MAX_UPLOADED_FILES_SIZE = 15728640;

export const TOPIC_MESSAGES_SIZE = 10000;
export const TOPICS_SIZE = 10000;

export const LIMITED_PAYMENT_DESCRIPTION_SIZE = 18;
export const MAX_PAYMENT_DESCRIPTION_SIZE = 140;

export const MAX_TOPIC_SUBJECT_LENGTH = 100;
export const MAX_MESSAGE_LENGTH = 2000;

export const PAGINATION_SIZES = [20, 100];

export const PASSWORD_SPECIAL_SYMBOLS = '!"#$%&\'()*+,-.:;<=>?@[]^_`{/|\\}~';

export const EXCLUDED_COUNTRIES = ['by', 'ru'];

export const JURISDICTION_CODES = [
  'ae_az',
  'us_al',
  'us_ak',
  'al',
  'us_az',
  'us_ar',
  'aw',
  'au',
  'bs',
  'bh',
  'bd',
  'bb',
  'by',
  'be',
  'bz',
  'bm',
  'bo',
  'br',
  'bg',
  'us_ca',
  'kh',
  'ca',
  'us_co',
  'us_ct',
  'hr',
  'cw',
  'cy',
  'us_de',
  'dk',
  'us_dc',
  'do',
  'ae_du',
  'fi',
  'us_fl',
  'fr',
  'gf',
  'us_ga',
  'de',
  'gi',
  'gr',
  'gl',
  'gp',
  'gg',
  'us_hi',
  'hk',
  'is',
  'us_id',
  'in',
  'us_in',
  'us_ia',
  'ir',
  'ie',
  'im',
  'il',
  'jm',
  'jp',
  'je',
  'us_ks',
  'us_ky',
  'lv',
  'li',
  'us_la',
  'lu',
  'us_me',
  'my',
  'mt',
  'mq',
  'us_md',
  'us_ma',
  'mu',
  'yt',
  'mx',
  'us_mi',
  'us_mn',
  'us_ms',
  'us_mo',
  'md',
  'us_mt',
  'me',
  'mm',
  'us_ne',
  'nl',
  'us_nv',
  'ca_nb',
  'us_nh',
  'us_nj',
  'us_nm',
  'us_ny',
  'nz',
  'ca_nl',
  'us_nc',
  'us_nd',
  'no',
  'ca_ns',
  'us_oh',
  'us_ok',
  'us_or',
  'pk',
  'pa',
  'us_pa',
  'pl',
  'ca_pe',
  'pr',
  'ca_qc',
  'us_ri',
  'ro',
  'rw',
  're',
  'bl',
  'mf',
  'pm',
  'sg',
  'sk',
  'si',
  'za',
  'us_sc',
  'us_sd',
  'es',
  'se',
  'ch',
  'tj',
  'tz',
  'us_tn',
  'us_tx',
  'th',
  'to',
  'tn',
  'ug',
  'ua',
  'gb',
  'us_ut',
  'vu',
  'us_vt',
  'vn',
  'us_va',
  'us_wa',
  'us_wv',
  'us_wi',
  'us_wy'
];

export const STATIC_COUNTRY_CODES = [
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'KH',
  'CM',
  'CA',
  'KY',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CK',
  'CR',
  'HR',
  'CW',
  'CY',
  'CZ',
  'CI',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'SZ',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GY',
  'HT',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KR',
  'XK',
  'KW',
  'KG',
  'LV',
  'LB',
  'LS',
  'LR',
  'LI',
  'LT',
  'LU',
  'MO',
  'MG',
  'MW',
  'MY',
  'MV',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NE',
  'NG',
  'NU',
  'NF',
  'MK',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RO',
  'RW',
  'RE',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'ZA',
  'GS',
  'ES',
  'LK',
  'SR',
  'SJ',
  'SE',
  'CH',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TG',
  'TK',
  'TO',
  'TT',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UY',
  'UZ',
  'VU',
  'VN',
  'VG',
  'VI',
  'WF',
  'ZM',
  'AX'
];
