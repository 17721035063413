import * as Yup from 'yup';
import {
  LATIN_LETTERS_AND_DIGITS_REG_EXP,
  START_WITH_GB_REG_EXP,
  GBP_SORT_CODE_REG_EXP,
  getCustomerNameRegExp,
  validateCommentFieldByPaymentMethod
} from './utils';
import { getMaxDescriptionSize } from '../../utils';
import { PAYMENT_METHOD, RECIPIENT_TYPES } from 'components/common/constants';
import { formatSortCode, trimTextContent } from 'services/utils';

export default ({
  i18n,
  onChange,
  onSubmit,
  customerNumber,
  model,
  currency,
  previousTransaction,
  paymentMethod,
  providerType
}) => ({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: {
    amount: `${previousTransaction?.amount || ''}`,
    recipient_wallet: previousTransaction?.counterparty?.iban || '',
    recipient_type: RECIPIENT_TYPES[previousTransaction?.recipient_type] || RECIPIENT_TYPES.COMPANY,
    company_name: previousTransaction?.counterparty?.party_name,
    first_name: previousTransaction?.counterparty?.party_name.split(' ')[0] || null,
    last_name: previousTransaction?.counterparty?.party_name.split(' ')[1] || null,
    comment: previousTransaction?.comment,
    documents: null,
    account_number: previousTransaction?.counterparty?.account_number || '',
    sort_code: formatSortCode(previousTransaction?.counterparty?.sort_code) || ''
  },
  initialStatus: { company_name: true, recipient_wallet: true },
  validationSchema: Yup.object().shape(
    {
      recipient_wallet: Yup.string().when('account_number', {
        is: (account_number) => !account_number,
        then: (schema) =>
          schema
            .nullable(true)
            .required(i18n.getMessage('client.validation.message.isEmpty'))
            .max(34, i18n.getMessage('client.validation.message.maxSize', { size: 34 }))
            .trim()
            // trick for using `currency` variable in checking value in validation
            .when('recipient_type', (recipient_type, schema) => {
              if (currency === 'GBP' && paymentMethod !== PAYMENT_METHOD.SWIFT) {
                return schema.matches(START_WITH_GB_REG_EXP, {
                  message: i18n.getMessage('client.validation.message.startWithGB')
                });
              }
              return schema;
            })
            .matches(LATIN_LETTERS_AND_DIGITS_REG_EXP, {
              message: i18n.getMessage('client.validation.message.isOnlyLatinLettersAndDigits')
            }),
        otherwise: (schema) => schema.nullable(true)
      }),

      account_number: Yup.string().when('recipient_wallet', {
        is: (recipient_wallet) => !recipient_wallet,
        then: (schema) =>
          schema
            .nullable(true)
            .required(i18n.getMessage('client.validation.message.isEmpty'))
            .max(11, i18n.getMessage('client.validation.message.maxSize', { size: 11 }))
            .min(8, i18n.getMessage('client.validation.message.minSize', { size: 8 }))
            .matches(LATIN_LETTERS_AND_DIGITS_REG_EXP, {
              message: i18n.getMessage('client.validation.message.isOnlyLatinLettersAndDigits')
            }),
        otherwise: (schema) => schema.nullable(true)
      }),

      sort_code: Yup.string().when('recipient_type', (recipient_type, schema) => {
        if (currency === 'GBP') {
          return schema.nullable(true).matches(GBP_SORT_CODE_REG_EXP, {
            message: i18n.getMessage('client.validation.message.sortCodeFormat')
          });
        }
        return schema.nullable(true);
      }),
      amount: Yup.string().nullable(true).required(i18n.getMessage('client.validation.message.isEmpty')),
      recipient_type: Yup.string().nullable(false).required(i18n.getMessage('client.validation.message.isEmpty')),
      company_name: Yup.string()
        .nullable(true)
        .when('recipient_type', {
          is: RECIPIENT_TYPES.COMPANY,
          then: (schema) =>
            schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .max(35, i18n.getMessage('client.validation.message.maxSize', { size: 35 }))
              .matches(getCustomerNameRegExp(), {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
                  availableSymbols: "“.“, “,“, “-“, “/“, “:“, “?“, “(“, “)“, “'“, “+“, “CrLf“, “Space“"
                })
              }),
          otherwise: (schema) => schema.nullable(true)
        }),
      first_name: Yup.string()
        .nullable(true)
        .when('recipient_type', {
          is: RECIPIENT_TYPES.INDIVIDUAL,
          then: (schema) =>
            schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .max(35, i18n.getMessage('client.validation.message.maxSize', { size: 35 }))
              .matches(getCustomerNameRegExp(), {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
                  availableSymbols: "“.“, “,“, “-“, “/“, “:“, “?“, “(“, “)“, “'“, “+“, “CrLf“, “Space“"
                })
              }),
          otherwise: (schema) => schema.nullable(true)
        }),
      last_name: Yup.string()
        .nullable(true)
        .when('recipient_type', {
          is: RECIPIENT_TYPES.INDIVIDUAL,
          then: (schema) =>
            schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .max(35, i18n.getMessage('client.validation.message.maxSize', { size: 35 }))
              .matches(getCustomerNameRegExp(), {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
                  availableSymbols: "“.“, “,“, “-“, “/“, “:“, “?“, “(“, “)“, “'“, “+“, “CrLf“, “Space“"
                })
              }),
          otherwise: (schema) => schema.nullable(true)
        }),
      comment: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('client.validation.message.isEmpty'))
        .test(
          'openpayd-comment-validation',
          paymentMethod === PAYMENT_METHOD.SWIFT
            ? i18n.getMessage('transfer.form.error.comment.SWIFT')
            : paymentMethod === PAYMENT_METHOD.FASTER_PAYMENTS
              ? i18n.getMessage('transfer.form.error.comment.FASTER_PAYMENTS')
              : i18n.getMessage('transfer.form.error.comment.OTHER_METHODS'),
          (comment) => validateCommentFieldByPaymentMethod(paymentMethod, trimTextContent(comment))
        )
        .max(
          getMaxDescriptionSize(providerType, paymentMethod, currency),
          i18n.getMessage('client.validation.message.maxSize', {
            size: getMaxDescriptionSize(providerType, paymentMethod, currency)
          })
        )
    },
    [['account_number', 'recipient_wallet']]
  ),
  onSubmit: (values) => {
    onChange(values);
    onSubmit({ customerNumber, providerData: values, model });
  }
});
