import i18n from 'i18n';
import {
  CURRENCY_TYPES,
  LIMITED_PAYMENT_DESCRIPTION_SIZE,
  MAX_PAYMENT_DESCRIPTION_SIZE,
  PAYMENT_METHOD,
  PAYMENT_METHODS,
  PAYMENT_PROVIDERS
} from 'components/common/constants';

const getValuesInCorrectOrder = (paymentMethods, order) => {
  const result = [];

  order.forEach((method) => {
    if (paymentMethods.includes(method)) {
      result.push(method);
    }
  });

  paymentMethods.forEach((method) => {
    if (!result.includes(method)) {
      result.push(method);
    }
  });

  return result;
};

export const filteringPaymentMethods = (paymentMethods) => {
  if (!paymentMethods) {
    return paymentMethods;
  } else {
    return PAYMENT_METHODS.filter((method) => paymentMethods.includes(method));
  }
};

export const sortingPaymentMethods = (paymentMethods, currency, exampleArrays, isNonIban, providerType) => {
  if (!paymentMethods || paymentMethods.length < 2) return paymentMethods;

  let exampleArr;
  if (currency === CURRENCY_TYPES.EUR) {
    exampleArr = exampleArrays.EUR;
  } else if (currency === CURRENCY_TYPES.GBP) {
    exampleArr = exampleArrays.GBP;
  } else if (providerType === PAYMENT_PROVIDERS.GRAPHEREX) {
    exampleArr = exampleArrays.CRYPTO;
  } else {
    exampleArr = exampleArrays.OTHER;
  }

  if (isNonIban) {
    exampleArr = exampleArr.filter(
      (method) => method !== PAYMENT_METHOD.INTERNAL && method !== PAYMENT_METHOD.INTERNAL_PROVIDER
    );
  }

  return getValuesInCorrectOrder(paymentMethods, exampleArr);
};

export const moveSelectedMethodToFront = (paymentMethods, selectedMethod) => {
  return [selectedMethod, ...paymentMethods.filter((item) => item !== selectedMethod)];
};

export const getPaymentMethodsOptions = (paymentMethods) => {
  return paymentMethods?.reduce((results, method) => {
    if (paymentMethods.includes(method)) {
      results.push({ key: method, value: i18n.getMessage(`transfer.paymentMethod.${method}`) });
    }

    return results;
  }, []);
};

export const filterPaymentAccounts = (accounts) => {
  return accounts.filter((account) => account.transfer_provider !== PAYMENT_PROVIDERS.WALLESTER);
};

export const getMaxDescriptionSize = (paymentProvider, paymentMethod, currency) => {
  return paymentMethod === PAYMENT_METHOD.FASTER_PAYMENTS
    ? LIMITED_PAYMENT_DESCRIPTION_SIZE
    : MAX_PAYMENT_DESCRIPTION_SIZE;
};

export const findUserAccount = (userAccounts, ibanToCheck, currentAccountCurrency) => {
  let foundedAccount = null;

  if (!userAccounts || !ibanToCheck || userAccounts.length === 0) {
    return foundedAccount;
  }

  const accountsWithSameNumber = userAccounts.filter(
    (account) =>
      (account.iban && account.iban?.iban === ibanToCheck) ||
      (account.multi_iban && account.multi_iban?.iban === ibanToCheck)
  );

  if (accountsWithSameNumber.length === 1) {
    foundedAccount = accountsWithSameNumber[0];
  } else if (accountsWithSameNumber.length > 1) {
    foundedAccount = accountsWithSameNumber.find((account) => account.currency !== currentAccountCurrency);
  }

  return foundedAccount;
};
