import { useContext, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as SupportIcon } from 'assets/support.svg';
import i18nContext from 'components/i18n-context';
import { ACCOUNT_PROPERTIES, ACCOUNT_TOOLTIP_TEXT } from 'components/common/constants';
import { ContactUs } from 'components/common/ContactUs/ContactUs';
import LatestTransactionsCompact from 'components/common/LatestTransactions/LatestTransactionsCompact';
import NoOutlet from 'components/common/NoOutlet/NoOutlet';
import PopUpScheme from 'components/common/PopUpScheme/PopUpScheme';
import Verification from 'components/Verification/Verification';
import { ROUTE_PATHS } from 'routes/constants';
import { isCryptoProvider } from 'services/utils';
import { Card } from 'uikit/Card/Card';
import { PopUp } from 'uikit/PopUp/PopUp';
import './Crypto.scss';

const Crypto = ({ userStore, paymentStore, transactionsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [popUpState, setPopUpState] = useState({ open: false, account: {} });

  const showPopup = (account) => {
    setPopUpState({ open: true, account: account });
  };

  const closePopUp = () => {
    setPopUpState({ open: false, account: {} });
  };

  const goToTransaction = (account) => {
    transactionsStore.setAccount(account);
    transactionsStore.setIsRedirectFromAccountCard(true);
    navigate(ROUTE_PATHS.TRANSACTIONS);
  };

  const goToPayment = (account) => {
    paymentStore.setSelectedAccount(account);
    navigate(ROUTE_PATHS.NEW_PAYMENT);
  };

  const hasCryptoAccounts = () => {
    return userStore.userAccounts.some((account) => isCryptoProvider(account.transfer_provider));
  };

  const getCryptoWalletsNumberList = (accounts) => {
    return accounts
      .filter((account) => isCryptoProvider(account.transfer_provider))
      .map((account) => account.wallet_number);
  };

  if (userStore.userData?.account && userStore.isCustomerVerified !== null && !userStore.isCustomerVerified) {
    return (
      <section className={'right-section verifications'}>
        <Verification />
      </section>
    );
  }

  if (!userStore.userData?.account) {
    return <NoOutlet error={userStore.error} />;
  }

  return (
    <>
      {!hasCryptoAccounts() ? (
        <ContactUs
          icon={<SupportIcon />}
          header={i18n.getMessage('crypto.noAccounts.title')}
          description={i18n.getMessage('crypto.noAccounts.description')}
          button={i18n.getMessage('crypto.noAccounts.button')}
        />
      ) : (
        <section className={'right-section crypto'}>
          <div className={'crypto-header'}>
            <p className={'crypto-header-text'}>{i18n.getMessage('crypto.accounts.header.text')}</p>
            <Link className={'link-normal view-all-accounts-link'} to={ROUTE_PATHS.ACCOUNTS}>
              {i18n.getMessage('crypto.button.viewAll')}
            </Link>
          </div>
          <div className={'crypto-card-wrapper'}>
            {userStore.userAccounts?.map((account) => {
              const isCryptoWallet = isCryptoProvider(account.transfer_provider);
              if (!isCryptoWallet) return null;

              return (
                <Card
                  key={account[ACCOUNT_PROPERTIES.ACCOUNT_NUMBER]}
                  type={'card'}
                  isCryptoAccount={isCryptoWallet}
                  account={account}
                  balance={account[ACCOUNT_PROPERTIES.AVAILABLE]}
                  currency={account[ACCOUNT_PROPERTIES.CURRENCY]}
                  networkCode={account[ACCOUNT_PROPERTIES.NETWORK]}
                  reserved={account[ACCOUNT_PROPERTIES.ON_HOLD]}
                  buttonText={'Review account'}
                  onExclaimPointClick={() => showPopup(account)}
                  onTransactionClick={() => goToTransaction(account)}
                  onPaymentClick={() => goToPayment(account)}
                  tooltipText={ACCOUNT_TOOLTIP_TEXT}
                />
              );
            })}
          </div>
          <PopUp className={'crypto-account-info'} show={popUpState.open} onClose={closePopUp}>
            <PopUpScheme activeAccount={popUpState.account} onClose={closePopUp} />
          </PopUp>
          <LatestTransactionsCompact
            isCryptoTransactions={true}
            customerNumber={userStore.userData.account.account_number}
            accountNumber={getCryptoWalletsNumberList(userStore.userAccounts)}
          />
        </section>
      )}
    </>
  );
};

Crypto.propTypes = {
  userStore: MobXPropTypes.observableObject,
  paymentStore: MobXPropTypes.observableObject,
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore,
  paymentStore: stores.paymentStore,
  transactionsStore: stores.transactionsStore
}))(observer(Crypto));
